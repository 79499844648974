// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-applications-js": () => import("./../../../src/pages/applications.js" /* webpackChunkName: "component---src-pages-applications-js" */),
  "component---src-pages-applications-mining-js": () => import("./../../../src/pages/applications/mining.js" /* webpackChunkName: "component---src-pages-applications-mining-js" */),
  "component---src-pages-home-applications-js": () => import("./../../../src/pages/home/applications.js" /* webpackChunkName: "component---src-pages-home-applications-js" */),
  "component---src-pages-home-components-js": () => import("./../../../src/pages/home/components.js" /* webpackChunkName: "component---src-pages-home-components-js" */),
  "component---src-pages-home-footer-js": () => import("./../../../src/pages/home/footer.js" /* webpackChunkName: "component---src-pages-home-footer-js" */),
  "component---src-pages-home-hero-js": () => import("./../../../src/pages/home/hero.js" /* webpackChunkName: "component---src-pages-home-hero-js" */),
  "component---src-pages-home-how-js": () => import("./../../../src/pages/home/how.js" /* webpackChunkName: "component---src-pages-home-how-js" */),
  "component---src-pages-home-index-js": () => import("./../../../src/pages/home/index.js" /* webpackChunkName: "component---src-pages-home-index-js" */),
  "component---src-pages-home-what-js": () => import("./../../../src/pages/home/what.js" /* webpackChunkName: "component---src-pages-home-what-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */)
}

