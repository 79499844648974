import React from "react";
import styled from "styled-components";
import { TimelineMax, Expo } from "gsap";

import logo from "../images/logo.svg";

function LoadingOverlay({ hidden, onFadedOut }) {
  const hideTl = React.useRef();
  const loadingTl = React.useRef();

  const $container = React.useRef();
  const $logo = React.useRef();
  const $loadingCircle = React.useRef();

  React.useEffect(() => {
    const revealTl = new TimelineMax({});

    revealTl
      .fromTo(
        $logo.current,
        1,
        {
          opacity: 0,
        },
        {
          opacity: 1,
        }
      )
      .fromTo(
        $loadingCircle.current,
        1,
        {
          opacity: 0,
        },
        {
          opacity: 1,
        }
      );
  }, []);

  React.useEffect(() => {
    if (!hidden) {
      loadingTl.current = new TimelineMax({
        yoyo: true,
        repeat: -1,
      });
    }
  }, [hidden]);

  React.useEffect(() => {
    if (hidden) {
      hideTl.current = new TimelineMax({
        yoyo: true,
      });

      hideTl.current
        .to($loadingCircle.current, 0.6, {
          opacity: 0,
        })
        .to($logo.current, 1, {
          scale: 1.4,
          opacity: 0,
          ease: Expo.easeInOut,
        })
        .to(
          $container.current,
          1,
          {
            opacity: 0,
          },
          "-=.2"
        )
        .add(() => {
          onFadedOut();
        }, "-=.5")
        .set($container.current, {
          display: "none",
        });
    }
  }, [hidden]);

  return (
    <Container ref={$container}>
      <Center>
        <LoadingSvg ref={$loadingCircle} width={82} height={82}>
          <circle className="outer" r={40} cx={41} cy={41} />
          <circle className="inner" r={40} cx={41} cy={41} />
        </LoadingSvg>

        <Logo ref={$logo}>
          <img src={logo} alt="Primis Group" />
        </Logo>
      </Center>
    </Container>
  );
}

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
  bottom: 0;
  right: 0;

  background-color: #111;
`;

const Center = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translateX(-50%) translateY(-50%);
`;

const LoadingSvg = styled.svg`
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translateX(-50%) translateY(-50%) rotate(-90deg);

  circle {
    fill: transparent;
    stroke-width: 1px;
    stroke: white;

    &.inner {
      opacity: 0.1;
    }

    &.outer {
      stroke-dasharray: 256;
      stroke-dashoffset: 256;
      stroke-width: 1px;
      animation: spin 3s infinite ease-in;
      animation-delay: 3.5s;
    }
  }

  @keyframes spin {
    0% {
      stroke-dashoffset: 256;
    }

    100% {
      stroke-dashoffset: 0;
    }
  }
`;

const Logo = styled.div`
  width: 18px;
  transform-origin: center;

  img {
    margin: 0;
    width: 100%;
    display: block;
  }
`;

export default LoadingOverlay;
