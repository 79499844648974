import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";

import styled, {
  ThemeProvider,
  createGlobalStyle,
} from "styled-components/macro";

import "react-perfect-scrollbar/dist/css/styles.css";

import MouseThing from "../lib/mouse-thing/mouse-thing";

import theme from "../const/theme";

import "../components/layout.css";
import Header from "../components/header";
import Pointer from "../components/pointer";
import { LoadingProvider } from "../utility/useLoading";

const Layout = ({ bg, children }) => {
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <LoadingProvider>
          <MouseThing>
            <ThemeProvider theme={theme}>
              <>
                <Global />
                <Pointer />
                <Header siteTitle={data.site.siteMetadata.title} />

                <PageWrapper>
                  <main>{children}</main>
                </PageWrapper>
              </>
            </ThemeProvider>
          </MouseThing>
        </LoadingProvider>
      )}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

const Global = createGlobalStyle`
  html,
  body {
    background-color: ${props => props.theme.colors.black};
  }

  html {
    overflow-x: hidden;
  }

  .link {
    color: white;
    text-transform: uppercase;
    text-decoration: none;
    font-size: 14px;

    font-weight: 600;
    position: relative;

    &:after {
      content: "";
      width: 100%;
      position: absolute;
      top: 100%;
      left: 0;

      margin-top: 10px;

      transform: scaleX(0);

      border-bottom: 2px solid ${props => props.theme.colors.high};
      transform-origin: left;
      transition: transform 0.3s ease;
    }

    &:hover:after, &.active {
      transform: scaleX(1);
      transform-origin: right;
    }
  }

  .ps__rail-y {
    z-index: 300;
    width: 12px;
    opacity: 0;

    &:hover {
      background-color: rgba(255, 255, 255, 0.4);
      width: 12px;

      .ps__thumb-y {
        width: 12px;
        background-color: ${props => props.theme.colors.high};
      }
    }
  }

  .ps__thumb-y {
    z-index: 310;
    border-radius: 0;
    background-color: ${props => props.theme.colors.high};
    right: 0;
    opacity: 0;

    transition: opacity .3s ease;

    .ps--scrolling-y & {
      opacity: 1;
    }
  } 
`;

const PageWrapper = styled.div`
  position: relative;
  z-index: 2;

  color: white;

  font-family: "Cairo", sans-serif;
  line-height: 1.5;

  a {
    color: initial;
  }
`;

export default Layout;
