import { useContext, useEffect, useState } from "react";

import { MouseThingContext } from "../mouse-thing";

const useMouseThing = () => {
  const [key, setKey] = useState();
  const {
    mouse,
    setActiveContainer,
    setActiveArea,
    setAdditionalProps,
    activeContainer,
    additionalProps,
    activeArea,
    subscribe,
    unsubscribe,
  } = useContext(MouseThingContext);

  function update() {
    setKey(new Date().getTime());
  }

  useEffect(() => {
    subscribe(update);
    return () => unsubscribe(update);
  }, []);

  function handleMouseEnter({ area, additionalProps }, e) {
    console.log("handle mouse enter");

    setActiveArea(area);
    setActiveContainer(e.target);
    setAdditionalProps(additionalProps);

    // setKey(new Date().getTime())
  }

  function handleMouseLeave({ area, additionalProps }) {
    setActiveArea(undefined);
    setActiveContainer(false);
    setAdditionalProps(undefined);

    // setKey(new Date().getTime())
  }

  return {
    key,
    mouse,
    setActiveContainer,
    setActiveArea,
    setAdditionalProps,
    activeContainer,
    additionalProps,
    activeArea,
    mouseenter: area => e => handleMouseEnter(area, e),
    mouseleave: area => e => handleMouseLeave(area, e),
  };
};

export default useMouseThing;
