import React from "react";
import LoadingOverlay from "../components/loading-overlay";

const LoadingContext = React.createContext({
  isLoading: false,
});

export function LoadingProvider(props) {
  const [isLoading, setLoading] = React.useState(true);
  const [keys, setKeys] = React.useState([]);
  const [isPageReady, setPageReady] = React.useState(false);

  const addKey = React.useCallback(
    key => {
      setKeys([...keys, key]);
    },
    [keys, setKeys]
  );

  const removeKey = React.useCallback(
    key => {
      const newKeys = keys.filter(existingKeys => existingKeys !== key);
      setKeys(newKeys);
    },
    [keys, setKeys]
  );

  React.useEffect(() => {
    if (keys.length === 0) {
      setLoading(false);
    }
  }, [keys]);

  function handleLoadingOverlayFadeOut() {
    setPageReady(true);
  }

  return (
    <LoadingContext.Provider
      value={{
        isLoading,
        isPageReady,
        addKey,
        removeKey,
      }}
    >
      <>
        <LoadingOverlay
          hidden={!isLoading}
          onFadedOut={handleLoadingOverlayFadeOut}
        />
        {props.children}
      </>
    </LoadingContext.Provider>
  );
}

function useLoading(key) {
  const { addKey, removeKey } = React.useContext(LoadingContext);

  function onLoadingStart() {
    addKey(key);
  }

  function onLoaded() {
    removeKey(key);
  }

  return [onLoadingStart, onLoaded];
}

export function useLoadingState() {
  const { isLoading, isPageReady } = React.useContext(LoadingContext);

  return { isLoading, isPageReady };
}

export default useLoading;
