export default {
  colors: {
    black: "#0d0d0d",
    high: "#5FA2FF",
    mute: "#94a3b9",
    offWhite: "#efefef",
    white: "#fff",
  },
  fonts: {
    futura: "Futura, sans-serif",
    cairo: "Cairo, sans-serif",
  },
};
