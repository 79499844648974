// import React from "react";
// import styled from "styled-components/macro";
// import { Spring } from "react-spring/renderprops";

// import useMouseThing from "../lib/mouse-thing/hooks/useMouseThing";
// import PointerHelper from "../lib/mouse-thing/components/PointerHelper";

function Pointer(props) {
  // const {
  //   mouse,
  //   activeContainer,
  //   activeArea,
  //   mousedown,
  //   additionalProps,
  // } = useMouseThing();
  // const { x, y } = mouse;

  // const box = activeContainer && activeContainer.getBoundingClientRect();
  // const trapped = activeArea === "anchor" || activeArea === "big";

  return null;

  // return (
  //   <Container>
  //     <Spring
  //       from={{ x: 0, y: 0, scale: 1 }}
  //       to={{
  //         x: trapped ? box.x + box.width / 2 : x,
  //         y: trapped ? box.y + box.height / 2 + window.scrollY : y,
  //         // width: trapped ? Math.max(box.width, box.height) * 1.3 : 6,
  //         // height: trapped ? Math.max(box.width, box.height) * 1.3 : 6,
  //         width: trapped ? 100 : 60,
  //         height: trapped ? 100 : 60,
  //       }}
  //     >
  //       {({ x, y, width, height, scale }) => (
  //         <PointerHelper x={x} y={y}>
  //           <RedBall
  //             active={trapped}
  //             style={{
  //               transform: `scale(${scale})`,
  //               width,
  //               height,
  //             }}
  //           />
  //         </PointerHelper>
  //       )}
  //     </Spring>

  //     <Spring
  //       from={{ scale: 0.15 }}
  //       style={{
  //         scale: activeArea === "image" ? 1 : 0.12,
  //       }}
  //     >
  //       {({ scale }) => (
  //         <PointerHelper x={x} y={y}>
  //           <BlackBall
  //             style={{
  //               transform: `scale(${scale})`,
  //               color: mousedown && "white",
  //               border: "1px solid #ec5858",
  //             }}
  //             active={activeArea === "image"}
  //           >
  //             <span>{additionalProps && additionalProps.text}</span>
  //           </BlackBall>
  //         </PointerHelper>
  //       )}
  //     </Spring>
  //   </Container>
  // );
}

export default Pointer;

// const Container = styled.div`
//   position: absolute;
//   z-index: 2000;
// `;

// const RedBall = styled.div`
//   border-radius: 50%;
//   border: 1px solid ${props => props.theme.colors.high};
// `;

// const BlackBall = styled.div`
//   width: 4rem;
//   height: 4rem;
//   border-radius: 50%;

//   color: #121212;
//   display: flex;
//   align-items: center;
//   justify-content: center;

//   font-size: 12px;

//   background-color: white;

//   span {
//     text-transform: uppercase;
//     font-weight: bold;
//     transform-origin: center;
//     transform: translateY(100%);
//     opacity: 0;
//     transition: transform 0.3s 0.2s ease, opacity 0.3s 0.2s ease;
//   }

//   ${props =>
//     props.active &&
//     `
//     span {
//       opacity: 1;
//       transform: translateY(0%);
//       transition-delay: 0;
//     }
//   `}
// `;
