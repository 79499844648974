import React from "react";
import { Link } from "gatsby";
import styled from "styled-components/macro";

import logo from "../images/logo.svg";
import logotype from "../images/logotype.svg";
import MouseTrap from "../lib/mouse-thing/components/MouseTrap";

const Header = ({ onToggleClick, menuOpen }) => {
  const handleLanguageSelection = React.useCallback(e => {
    const urls = {
      it: "it.primisgroup.com",
      ru: "ru.primisgroup.com",
      fr: "fr.primisgroup.com",
      ar: "ar.primisgroup.com",
      en: "primisgroup.com",
    };

    window.location.href = `https://${urls[e.target.value]}`;
  }, []);

  return (
    <Container>
      <Wrapper>
        <Link to="/">
          <Logo src={logo} alt="Primis Group" />
        </Link>

        <Link
          to="/"
          css={`
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);

            display: none;

            @media screen and (min-width: 1280px) {
              display: block;
            }
          `}
        >
          <Logotype src={logotype} alt="Primis Group" />
        </Link>

        <Menu>
          <MenuItem to="#overview">Overview</MenuItem>
          <MenuItem to="#contacts">Contacts</MenuItem>
          <MenuItem>
            <select
              css={`
                -webkit-appearance: none;
                background-color: transparent;
                color: white;
                outline: none;
                border: none;

                &:active {
                  border: none;
                  outline: none;
                }
              `}
              value={process.env.GATSBY_LANG}
              className="lang-select"
              onChange={handleLanguageSelection}
            >
              <option value={"fr"}>FR</option>
              <option value={"ar"}>AR</option>
              <option value={"en"}>EN</option>
              <option value={"it"}>IT</option>
            </select>
          </MenuItem>
        </Menu>

        {/* <MenuToggle onClick={onToggleClick} active={menuOpen} /> */}
      </Wrapper>
    </Container>
  );
};

const Container = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 20px 35px;

  z-index: 1000;

  @media screen and (min-width: 800px) {
    padding: 2rem;
  }

  img {
    display: block;
    margin: 0;
  }

  &:after {
    content: "";
    position: absolute;

    top: 0;

    left: 0;
    right: 0;

    height: 140px;
    z-index: 1;

    pointer-events: none;
  }
`;

const Wrapper = styled.div`
  position: relative;
  z-index: 2;

  display: flex;
  justify-content: space-between;
  align-items: center;

  max-width: 1408px;
  margin: 0 auto;
`;

const Logo = styled.img`
  width: 16px;

  @media screen and (min-width: 800px) {
    width: 24px;
  }
`;

const Logotype = styled.img`
  width: 100px;

  @media screen and (min-width: 800px) {
    width: 124px;
  }
`;

// const MenuToggle = styled.div`
//   &:before,
//   &:after {
//     content: "";

//     display: block;

//     width: 18px;
//     height: 2px;
//     background-color: white;

//     margin: 6px 0;
//     transition: all 0.3s ease;
//     transform-origin: center center;
//   }

//   ${props =>
//     props.active &&
//     css`
//       &:before {
//         transform: rotate(45deg) translateY(5px);
//       }

//       &:after {
//         transform: rotate(-45deg) translateY(-6px);
//       }
//     `}
// `;

const Menu = styled.div`
  display: flex;
  > * + * {
    margin-left: 1.5rem;
  }
`;

function MenuItem(props) {
  return (
    <MouseTrap area="anchor">
      {({ ref }) => (
        <a className="link" ref={ref} href={props.to} isActive={props.isActive}>
          {props.children}
        </a>
      )}
    </MouseTrap>
  );
}

export default Header;
