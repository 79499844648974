import { useEffect } from "react";

export default function useTinyEvents(
  eventsMap,
  options = {
    container: undefined,
  }
) {
  let { container } = options;

  function handleEvent(e) {
    return eventsMap[e.type](e);
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (typeof container === "undefined") {
        container = window;
      }

      Object.keys(eventsMap).forEach(eventName => {
        console.log("add event");
        container && container.addEventListener(eventName, handleEvent);
      });

      return () => {
        Object.keys(eventsMap).forEach(eventName => {
          container && container.removeEventListener(eventName, handleEvent);
        });
      };
    }
  }, [container]);
}
