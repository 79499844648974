import React, { createContext, useState, useRef } from "react";
import useTinyEvents from "./hooks/useTinyEvents";

export const MouseThingContext = createContext({ x: 0, y: 0 });
export const { Provider, Consumer } = MouseThingContext;

export default function MouseThing({ children, renderPointer }) {
  const [mouse, setMouse] = useState({ x: 0, y: 0 });
  const [activeArea, setActiveArea] = useState(null);
  const [additionalProps, setAdditionalProps] = useState(null);
  const activeContainer = useRef(false);

  const updaters = useRef([]);

  function handleMouseMove(e) {
    setMouse({
      x: e.clientX,
      y: e.clientY + window.scrollY,
    });

    updaters.current.forEach(u => u());
  }

  useTinyEvents({
    mousemove: handleMouseMove,
  });

  const setActiveContainer = el => {
    activeContainer.current = el;
  };

  function subscribe(cb) {
    updaters.current.push(cb);
  }

  function unsubscribe(cb) {
    updaters.current = updaters.current.filter(x => x !== cb);
  }

  return (
    <Provider
      value={{
        mouse,
        subscribe,
        unsubscribe,
        activeArea,
        activeContainer: activeContainer.current,
        additionalProps,
        setActiveContainer,
        setActiveArea,
        setAdditionalProps,
      }}
    >
      <PureWrapper>{children}</PureWrapper>
    </Provider>
  );
}

class PureWrapper extends React.Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    return this.props.children;
  }
}
